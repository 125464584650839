<template>
  <div class="main-header">
    <div class="logo mx-24">
      <img src="@/assets/images/logo.png" alt />
    </div>

    <div @click="sideBarToggle" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="d-flex align-items-center">
      <!-- Mega menu -->
      <div
        :class="{ show: isMegaMenuOpen }"
        class="dropdown mega-menu d-none d-md-block"
        v-on-clickaway="closeMegaMenu"
      >
        <a
          href="#"
          class="btn text-muted dropdown-toggle mr-3"
          id="dropdownMegaMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="toggleMegaMenu"
        >
          <b-icon
            icon="plus"
            variant="primary"
            style="width: 30px; height: 30px"
          ></b-icon>
        </a>
        <div
          class="dropdown-menu text-left"
          :class="{ show: isMegaMenuOpen }"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="row m-0">
            <div class="col-md-12 p-4 text-left">
              <ul class="links">
                <li>
                  <a href="#">Sales Order</a>
                </li>
                <li>
                  <a href="#">Purchase Order</a>
                </li>
                <li>
                  <a href="#">Customer</a>
                </li>
                <li>
                  <a href="#">Vendor</a>
                </li>
                <li>
                  <a href="#">Product</a>
                </li>
                <li>
                  <a href="#">Expense</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- / Mega menu -->
      <div class="search-bar" @click="toggleSearch">
        <input type="text" placeholder="Search" />
        <i class="search-icon text-muted i-Magnifi-Glass1"></i>
      </div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
      <a href="#" class="mr-2 btn btn-rounded btn-outline-primary">POS</a>
      <div class="dropdown">
        <b-dropdown
          id="dropdown"
          text="Dropdown Button"
          class="m-md-2"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <i
              class="i-Globe text-muted header-icon"
              role="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
          </template>
          <div class="menu-icon-grid">
              <a
                ><i
                  title="en"
                  class="flag-icon flag-icon-squared flag-icon-gb"
                ></i>
                English
              </a>
              <a
                ><i
                  title="fr"
                  class="flag-icon flag-icon-squared flag-icon-fr"
                ></i>
                <span class="title-lang">French</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-sa"
                ></i>
                <span class="title-lang">Arabic</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-tr"
                ></i>
                <span class="title-lang">Turkish</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-cn"
                ></i>
                <span class="title-lang">Simplified Chinese</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-th"
                ></i>
                <span class="title-lang">Thaï</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-in"
                ></i>
                <span class="title-lang">Hindi</span></a
              >
              <a
                ><i
                  title="de"
                  class="flag-icon flag-icon-squared flag-icon-de"
                ></i>
                <span class="title-lang">German</span></a
              >
              <a
                ><i
                  title="es"
                  class="flag-icon flag-icon-squared flag-icon-es"
                ></i>
                <span class="title-lang">Spanish</span></a
              >
              <a
                ><i
                  title="it"
                  class="flag-icon flag-icon-squared flag-icon-it"
                ></i>
                <span class="title-lang">Italien</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-id"
                ></i>
                <span class="title-lang">Indonesian</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-cn"
                ></i>
                <span class="title-lang">Traditional Chinese</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-ru"
                ></i>
                <span class="title-lang">Russian</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-vn"
                ></i>
                <span class="title-lang">Vietnamese</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-kr"
                ></i>
                <span class="title-lang">Korean</span></a
              >
              <a
                ><i
                  title="sa"
                  class="flag-icon flag-icon-squared flag-icon-bd"
                ></i>
                <span class="title-lang">Bangla</span></a
              >
              <a
                ><i
                  title="pt"
                  class="flag-icon flag-icon-squared flag-icon-pt"
                ></i>
                <span class="title-lang">Portuguese</span></a
              >
          </div>
        </b-dropdown>
      </div>

      <!-- Notificaiton -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 badge-top-container"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <span class="badge badge-primary">3</span>
            <i class="i-Bell text-muted header-icon"></i>
          </template>
          <!-- Notification dropdown -->
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
            ref="myData"
            class="dropdown-menu-right rtl-ps-none notification-dropdown ps scroll"
          >
            <!-- <div class="dropdown-menu-right rtl-ps-none notification-dropdown"> -->
            <div class="dropdown-item d-flex">
              <div class="notification-icon">
                <i class="i-Speach-Bubble-6 text-primary mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>New message</span>
                  <!-- <span class="badge badge-pill badge-primary ml-1 mr-1">new</span> -->
                  <span class="flex-grow-1"></span>
                  <span class="text-small text-muted ml-auto">10 sec ago</span>
                </p>
                <p class="text-small text-muted m-0">
                  James: Hey! are you busy?
                </p>
              </div>
            </div>
            <div class="dropdown-item d-flex">
              <div class="notification-icon">
                <i class="i-Receipt-3 text-success mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>New order received</span>
                  <!-- <span class="badge badge-pill badge-success ml-1 mr-1">new</span> -->
                  <span class="flex-grow-1"></span>
                  <span class="text-small text-muted ml-auto">2 hours ago</span>
                </p>
                <p class="text-small text-muted m-0">1 Headphone, 3 iPhone x</p>
              </div>
            </div>
            <div class="dropdown-item d-flex">
              <div class="notification-icon">
                <i class="i-Empty-Box text-danger mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>Product out of stock</span>
                  <!-- <span class="badge badge-pill badge-danger ml-1 mr-1">3</span> -->
                  <span class="flex-grow-1"></span>
                  <span class="text-small text-muted ml-auto"
                    >10 hours ago</span
                  >
                </p>
                <p class="text-small text-muted m-0">
                  Headphone E67, R98, XL90, Q77
                </p>
              </div>
            </div>
            <div class="dropdown-item d-flex">
              <div class="notification-icon">
                <i class="i-Data-Power text-success mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>Server Up!</span>
                  <!-- <span class="badge badge-pill badge-success ml-1 mr-1">3</span> -->
                  <span class="flex-grow-1"></span>
                  <span class="text-small text-muted ml-auto"
                    >14 hours ago</span
                  >
                </p>
                <p class="text-small text-muted m-0">
                  Server rebooted successfully
                </p>
              </div>
            </div>
            <!-- </div> -->
          </vue-perfect-scrollbar>
        </b-dropdown>
      </div>
      <!-- Notificaiton End -->

      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              src="@/assets/images/faces/1.jpg"
              id="userDropdown"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </template>

          <div class="dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> Timothy Carlson
            </div>
            <a class="dropdown-item">Account settings</a>
            <a class="dropdown-item">Billing history</a>
            <a class="dropdown-item" href="#" @click.prevent="logoutUser"
              >Sign out</a
            >
          </div>
        </b-dropdown>
      </div>
    </div>
    <search-component
      :isSearchOpen.sync="isSearchOpen"
      @closeSearch="toggleSearch"
    ></search-component>
  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from "@/utils";
import Sidebar from "./Sidebar";
import searchComponent from "../common/search";
import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  components: {
    Sidebar,
    searchComponent,
  },

  data() {
    return {
      isDisplay: true,

      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false,
    };
  },
  mounted() {
    // document.addEventListener("click", this.closeMegaMenu);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",

      "changeSidebarProperties",
      "changeThemeMode",
      "signOut",
    ]),

    // megaMenuToggle() {
    //   this.isMegaMenuOpen = false;

    //   console.log("work");
    // },

    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      this.signOut();

      this.$router.push("/app/sessions/signIn");
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
      // console.log(this.isMouseOnMegaMenu);
      // if (!this.isMouseOnMegaMenu) {
      //   this.isMegaMenuOpen = !this.isMegaMenuOpen;
      // }
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle(el) {
      if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    },
  },
};
</script>

<style scoped>
.logo img {
  width: 40px !important;
  height: 40px !important;
}
.logo {
  width: auto !important;
}
::v-deep .dropdown-menu {
  height: 300px;
    overflow: scroll;
}
</style>

