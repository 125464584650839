<template>
  <div
    class="side-content-wrap"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
      ref="myData"
      class="sidebar-left rtl-ps-none ps scroll"
    >
      <div>
        <ul class="navigation-left">
          <li
            @mouseenter="toggleSubMenu"
            :class="{ active: selectedParentMenu == menu.key }"
            class="nav-item"
            :data-item="menu.key"
            :data-submenu="menu.hasSubmenu"
            v-for="(menu, index) in navMenuList"
            :key="index"
          >
            <router-link tag="a" class="nav-item-hold" :to="menu.to">
              <b-icon
                :icon="menu.icon"
                variant="primary"
                style="width: 30px; height: 30px"
              ></b-icon>
              <span class="nav-text">{{ menu.label }}</span>
              <div class="triangle"></div>
            </router-link>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>

    <vue-perfect-scrollbar
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="sidebar-left-secondary ps rtl-ps-none"
    >
      <div ref="sidebarChild">
        <!-- Submenu Dashboards -->
        <ul
          class="childNav d-none"
          :class="{ 'd-block': selectedParentMenu == subMenu.parent }"
          v-for="(subMenu, index) in submenuList"
          :data-parent="subMenu.parent"
          :key="index"
        >
          <li class="nav-item" v-for="(subMenuChild, index) in subMenu.child" :key="index">
            <router-link tag="a" class :to="subMenuChild.to">
              <i class="nav-icon i-Clock-3"></i>
              <span class="item-name">{{ subMenuChild.label }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
    <div
      @click="removeOverlay()"
      class="sidebar-overlay"
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
    ></div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {},

  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: "",
      isMobile,
      navMenuList: [
        {
          label: "Dashboard",
          key: "dashboards",
          to: "/app/dashboards/dashboard",
          hasSubmenu: false,
          icon: "layout-text-window-reverse",
        },
        {
          label: "Product",
          key: "product",
          to: "/products",
          hasSubmenu: false,
          icon: "card-list",
        },
        {
          label: "Vendor",
          key: "vendor",
          to: "/vendor",
          hasSubmenu: false,
          icon: "truck",
        },
        {
          label: "Customer",
          key: "customer",
          to: "/customer",
          hasSubmenu: false,
          icon: "person",
        },
        {
          label: "Quotation",
          key: "quotation",
          to: "/quotation",
          hasSubmenu: false,
          icon: "bookmark",
        },
        {
          label: "Purchase Order",
          key: "purchaseorder",
          to: "/purchase-order",
          hasSubmenu: false,
          icon: "cart",
        },
        {
          label: "Sales Order",
          key: "salesorder",
          to: "/sales-order",
          hasSubmenu: false,
          icon: "cart",
        },
        {
          label: "Inventory",
          key: "inventory",
          to: "/inventory",
          hasSubmenu: false,
          icon: "card-list",
        },
        {
          label: "Accounts",
          key: "accounts",
          to: "#",
          hasSubmenu: true,
          icon: "person-square",
        },
        // {
        //   label: "Reminder",
        //   key: "reminder",
        //   to: "/reminder",
        //   hasSubmenu: false,
        //   icon: "bell",
        // },
        {
          label: "Users",
          key: "users",
          to: "#",
          hasSubmenu: true,
          icon: "people",
        },
        {
          label: "Settings",
          key: "settings",
          to: "#",
          hasSubmenu: true,
          icon: "gear",
        },
      ],
      submenuList: [
        {
          parent: "sales",
          child: [
            {
              label: "Invoice",
              to: "#",
            },
            {
              label: "Dues",
              to: "#",
            },
            {
              label: "Customers",
              to: "#",
            },
          ],
        },
        {
          parent: "accounts",
          child: [
            {
              label: "Expense List",
              to: "/accounts",
            },
            {
              label: "Add Expense",
              to: "/addaccounts",
            },
            {
              label: "Expense Category",
              to: "/expense-category",
            },
            {
              label: "Add Expense Category",
              to: "/add-expense-category",
            },
          ],
        },
        {
          parent: "settings",
          child: [
            {
              label: "Company Profile",
              to: "#",
            },
            {
              label: "Country",
              to: "#",
            },
            {
              label: "City",
              to: "#",
            },
            {
              label: "Parameter",
              to: "#",
            },
            {
              label: "Currency Conversion",
              to: "#",
            },
          ],
        },
        {
          parent: "users",
          child: [
            {
              label: "List",
              to: "/users",
            },
            {
              label: "Add New",
              to: "/adduser",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("click", this.returnSelectedParentMenu);
    this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.returnSelectedParentMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",
      "changeSecondarySidebarPropertiesViaMenuItem",
      "changeSecondarySidebarPropertiesViaOverlay",
      "changeSidebarProperties",
    ]),

    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter((x) => x !== "")[1];

      if (currentParentUrl) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }
    },
    toggleSubMenu(e) {
      let hasSubmenu = e.target.dataset.submenu;
      let parent = e.target.dataset.item;
      if (hasSubmenu) {
        this.selectedParentMenu = parent;
        this.changeSecondarySidebarPropertiesViaMenuItem(true);
      } else {
        this.selectedParentMenu = parent;
        this.changeSecondarySidebarPropertiesViaMenuItem(false);
      }
      console.log(hasSubmenu, parent);
    },

    removeOverlay() {
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },

    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open");

      event.currentTarget.classList.toggle("open");

      dropdownMenus.forEach((dropdown) => {
        dropdown.classList.remove("open");
      });
    },
  },
};
</script>

<style lang="" scoped></style>
